import { Outlet } from 'react-router-dom'
import Sidebar from '../Sidebar/'
import { useEffect, useState } from 'react'
import './index.scss'

const Layout = () => {
  const [greeting, setGreeting] = useState('')

  useEffect(() => {
    const currentHour = new Date().getHours()
    let greetingMessage = ''
    if (currentHour < 12) {
      greetingMessage = 'Good Morning !'
    } else if (currentHour < 18) {
      greetingMessage = 'Good Afternoon !'
    } else {
      greetingMessage = 'Good Evening !'
    }
    setGreeting(greetingMessage)
  }, [])

  return (
    <div className="App">
      <Sidebar />
      <div className="page">
        <span className="tags top-tags-html">&lt;html&gt;</span><br />
        <span className="tags top-title-tags-html">&lt;title&gt;&nbsp;Hi, {greeting}&nbsp;&lt;/title&gt;</span><br />
        <span className="tags top-tags">&lt;body&gt;</span>

        <Outlet />
        <span className="tags bottom-tags">
          &lt;/body&gt;
          <br />
          <span className="bottom-tag-html">&lt;/html&gt;</span>
        </span>
      </div>
    </div>
  )
}

export default Layout
